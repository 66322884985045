import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;

// 模具列表
export const getMouldList = () => {
  return request({
    url: "/factory/type/list",
    method: "get",
  });
};

// 模具类型新增
export const getMesTypeAdd = () => {
  return request({
    url: "/factory/type",
    method: "get",
  });
};

// 查询模具管理详细
export function getModelDetail(id: any) {
  return request({
    url: "/factory/modelDetail/" + id,
    method: "get",
  });
}

// 新增模具管理
export function addModelDetail(data: any) {
  return request({
    url: "/factory/modelDetail",
    method: "post",
    data: data,
  });
}

// 修改模具管理
export function updateModelDetail(data: any) {
  return request({
    url: "/factory/modelDetail",
    method: "put",
    data: data,
  });
}

// 删除模具管理
export function delModelDetail(id: any) {
  return request({
    url: "/factory/modelDetail/" + id,
    method: "delete",
  });
}
